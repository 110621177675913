import { useContext } from 'react';
import { AuthenticationContext } from '../context/AuthenticationContext';
import TipoUnidadeEnum from '../modules/atendimento/enums/tipo-unidade.tsx';

export default function useAuth() {
  const { authenticatedUser, isUserAuthenticated, setAuthenticatedUser, setUserData } = useContext(AuthenticationContext);
  const getUnidade = () => authenticatedUser?.unidade || null;
  const getUnidadePai = () => authenticatedUser?.unidade?.unidadePai || null;
  const getIsAdmin = () => authenticatedUser?.isAdministrador ?? false; 
  const getTipoUnidadeSigla = () => authenticatedUser?.unidade?.tipoUnidade?.sigla || null;
  
  return {
    authenticatedUser,
    isUserAuthenticated,
    setAuthenticatedUser,
    setUserData,
    unidade: getUnidade(),
    unidadePai: getUnidadePai(),
    isAdministrador: getIsAdmin(),
    isUO: getTipoUnidadeSigla() === TipoUnidadeEnum.UnidadeOperacional,
    isDN: getTipoUnidadeSigla() === TipoUnidadeEnum.DiretorioNacional,
  };
}
