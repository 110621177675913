import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import { AuthenticationProvider } from "./context/AuthenticationContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      suspense: false,
    },
    mutations: {
      retry: 2,
    },
  },
});

Sentry.init({
  dsn: "https://af393812c9299c653f09d10a1670e0e4@sentry.isitics.com/8",
  integrations: [new BrowserTracing({ tracingOrigins: ["*"] }), new Sentry.Replay({ maskAllText: false, maskAllInputs: false, blockAllMedia: false })],

  beforeSend: (event) => {
    // Desabilita o Sentry no Localhost
    if (window.location.hostname === "localhost") {
      return null;
    }
    return event;
  },
  tracesSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <SnackbarProvider>
      <AuthenticationProvider>
        <App />
      </AuthenticationProvider>
    </SnackbarProvider>
    {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
  </QueryClientProvider>,
  document.getElementById("root")
);
