import { createContext, useEffect, useState } from "react";

const defaultValue = {
	authenticatedUser: undefined,
	isUserAuthenticated: false,
	setAuthenticatedUser: () => {},
	setUserData: () => {}
};

export const AuthenticationContext = createContext(defaultValue);

export function AuthenticationProvider({ children }) {
	// Estado inicial baseado no localStorage
	const storedData = localStorage.getItem("responseData");
	const initialAuth = storedData ? JSON.parse(storedData) : undefined;

	const [authenticatedUser, setUserData] = useState(initialAuth);
	const [isUserAuthenticated, setAuthenticatedUser] = useState(!!initialAuth?.login);

	useEffect(() => {
		try {
			if (initialAuth) {
				const { accessToken, user } = initialAuth;
				if (accessToken && user) {
					setAuthenticatedUser(true);
					setUserData(initialAuth);
				}
			}
		} catch (error) {
			console.error("Erro ao ler dados do localStorage:", error);
			setAuthenticatedUser(false);
		}
	}, []);

	return (
		<AuthenticationContext.Provider
			value={{ authenticatedUser, isUserAuthenticated, setAuthenticatedUser, setUserData }}>
			{children}
		</AuthenticationContext.Provider>
	);
}
