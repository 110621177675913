import React, { createContext, useState, useContext, ReactNode } from 'react';

interface LoadingContextType {
    isLoading: boolean;
    showLoading: () => void;
    closeLoading: () => void;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

interface LoadingProviderProps {
    children: ReactNode;
    initialState?: boolean;
}

export const LoadingProvider = ({ children, initialState = false }: LoadingProviderProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(initialState);

    const showLoading = () => setIsLoading(true);
    const closeLoading = () => setIsLoading(false);

    return (
        <LoadingContext.Provider value={{ isLoading, showLoading, closeLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = (): LoadingContextType => {
    const context = useContext(LoadingContext);
    if (context === undefined) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }
    return context;
};
