import "@fontsource/inter"; // Defaults to weight 400
import { StylesProvider, createGenerateClassName } from "@material-ui/core/styles";
import { CssBaseline, CssVarsProvider, GlobalStyles } from "@mui/joy";
import * as Sentry from "@sentry/react";
import React from "react";
import { LoadingProvider } from "./context/loading.context.tsx";
import "./index.css";
import customTheme from "./layout/theme.ts";
import Routes from "./routes";
import ErrorBoundary from "./comm/errorBoundary.tsx";

const generateClassName = createGenerateClassName({
  seed: "Sgt",
});

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Algo deu errado:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Tentar novamente</button>
    </div>
  );
}

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <ErrorBoundary>
        <LoadingProvider initialState={false}>
          <CssVarsProvider disableTransitionOnChange theme={customTheme}>
            <StylesProvider generateClassName={generateClassName} injectFirst>
              <GlobalStyles
                defaultTheme={customTheme}
                styles={{
                  "[data-feather], .feather": {
                    color: "var(--Icon-color)",
                    margin: "var(--Icon-margin)",
                    fontSize: "var(--Icon-fontSize, 20px)",
                    width: "1em",
                    height: "1em",
                  },
                }}
              />
              <>
                <svg style={{ position: "absolute", width: 0, height: 0 }}>
                  <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop stopColor="#0e3250" offset="0%" /> {/* Azul intenso */}
                    <stop stopColor="#0087FF" offset="100%" /> {/* Azul claro */}
                  </linearGradient>
                </svg>
              </>
              <Routes />
              <CssBaseline />
            </StylesProvider>
          </CssVarsProvider>
        </LoadingProvider>
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  );
}

export default App;
